<template>
	<PopupTwoCol name="torches"
	text="<h3>Les torches</h3><br/><p>En tant qu’organes de sécurité, les torches agissent comme la soupape de votre cocotte-minute : les gaz sont évacués pour éviter une surpression et la combustion permet d’éviter tout risque d’explosion.</p><br/><h3>Innovation</h3><p>Des drones sont utilisés pour visualiser et inspecter des équipements difficilement accessibles comme les cheminées, les torches… Grâce à une caméra fixée sur l’appareil, des prises de vues détaillées sont effectuées. La plateforme de Donges a été la première raffinerie Total en France à utiliser des drones pour ses inspections.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/torche_vhywt8_c_scale_w_350.jpg 350w,
		img/popup/torche_vhywt8_c_scale_w_708.jpg 708w,
		img/popup/torche_vhywt8_c_scale_w_970.jpg 970w"
		data-src="img/popup/torche_vhywt8_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/pop-up_torche_legende_drone_c1xrof_c_scale_w_350.jpg 350w,
		img/popup/pop-up_torche_legende_drone_c1xrof_c_scale_w_726.jpg 726w,
		img/popup/pop-up_torche_legende_drone_c1xrof_c_scale_w_970.jpg 970w"
		data-src="img/popup/pop-up_torche_legende_drone_c1xrof_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
